/*
 * Slider
 * Requires Lodash.js && TweenMax.js (GSAP)
 *
 * */
;
import _ from "lodash";
import templates from "./templates";

const calendar = function(container, startDate){
  "use strict";

  var data= {};
  var currentLayout="days";
  var date= startDate;
  var today= new Date(date);
  var currentDecadeStart= date.getFullYear()-date.getFullYear()%10;

  // dayTitles don't change
  data.dayTitles = [];
  _.each(locale.dayNames, function(element){
    data.dayTitles.push({
      name: element.substring(0,2)
    });
  });

  // create year-data for Decade Layout
  var createYears = function(){
    data.years = [];
    for(var i=currentDecadeStart-1; i<currentDecadeStart+11; i++){
      data.years.push({
        name: i,
        properties: i === today.getFullYear() ? "current" : ""
      });
    }
  };

  // create month-data for Month Layout
  var createMonths = function(){
    data.months = [];

    var currentMonth;
    if(date.getFullYear() === today.getFullYear()){
      currentMonth = today.getMonth();
    }

    _.each(locale.monthNames, function(element, index){
      data.months.push({
        name: element.substring(0,3),
        properties: currentMonth === index ? "current" : ""
      });
    });
  };

  var daysInMonth = function(year, month){
    return new Date(year, month+1, 0).getDate();
  };

  // create day-data for Day Layout
  var createDays = function(){
    data.days = [];

    // days of previous month
    var prevDays = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    prevDays = prevDays === 0 ? 7 : prevDays;

    var prevDayCount = daysInMonth(date.getFullYear(), date.getMonth()-1);
    for(var i=prevDays; i>0; i--){
      data.days.push({
        name: (prevDayCount-i+1),
        properties: "prev"
      });
    }

    var dayCount = daysInMonth(date.getFullYear(), date.getMonth());
    var day = new Date(date);
    for(i=0;i<dayCount;i++){
      day.setDate(i+1);

      data.days.push({
        name: day.getDate(),
        properties: day.getTime() === today.getTime() ? "current" : ""
      });
    }

    // days of next month
    var nextDays = 42 - prevDays - dayCount;
    for(i=0;i<nextDays;i++){
      data.days.push({
        name: (i+1),
        properties: "next"
      });
    }
  };

  // Render new Calendar Template depending on Layout
  var render = function(){
    data.layout= currentLayout;

    if(currentLayout === "years"){
      data.title = currentDecadeStart + "-" + (currentDecadeStart+9);
      createYears();
    }else if(currentLayout === "months"){
      data.title = date.getFullYear();
      createMonths();
    }else{
      data.title = locale.monthNames[date.getMonth()] + " " + date.getFullYear();
      createDays();
    }

    container.html("");
    container.append(templates.calendar(data));
  };

  // Switch to the Next or Previous Month/Year/Decade (Depending on Layout)
  var nextDate= function(direction){
    switch(currentLayout){
      case "years":
        currentDecadeStart+= 10*direction;
        render();
        break;
      case "months":
        date.setFullYear(date.getFullYear() + direction);
        render();
        break;
      default:
        date.setMonth(date.getMonth() + direction);
        render();
        container.trigger("selectMonth", new Date(date));
    }
  };

  // Change the Layout and Rerender
  var switchLayout= function(layout){
    // update decade
    if(layout === "years"){
      currentDecadeStart= date.getFullYear()-date.getFullYear()%10;
    }
    currentLayout = layout;
    render();
  };

  // Click Event Handling
  var handleEvent= function(event){
    var el= event.target;

    if(el.classList.contains("next")){
      nextDate(1);
    }else if(el.classList.contains("prev")){
      nextDate(-1);
    }else if(el.classList.contains("title")){
      switch(currentLayout){
        case "years":
          break;
        case "months":
          switchLayout("years");
          break;
        default:
          switchLayout("months");
      }

    }else if(el.classList.contains("year")){
      date.setYear(+$(el).text());
      switchLayout("months");

    }else if(el.classList.contains("month")){
      date.setMonth($(el).index());
      switchLayout("days");
      container.trigger("selectMonth", new Date(date));

    // click on already selected day
    }else if(el.classList.contains("day") && el.classList.contains("selected")){
      $(el).removeClass("selected");
      container.trigger("selectMonth", new Date(date));

    // click on eventDay
    }else if(el.classList.contains("day") && el.classList.contains("event")){
      date.setDate(+$(el).text());
      $(el).addClass("selected").siblings(".selected").removeClass("selected");
      container.trigger("selectDay", new Date(date));
    }
  };

  // highlights days with events
  container.data.setEventDays= function(eventDays){
    _.each($(".day:not(.prev, .next)",container), function(element, index){
      if(eventDays[index]){
        element.classList.add("event");
      }
    });
  };

  // requests an calendar at a specific date
  container.data.resetTo= function(resetDate){
    date= resetDate;
    render();
  };

  container.on("click", handleEvent);

  render();
  return container;
};

export default calendar;
