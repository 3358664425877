import events from "./events";
import fellows from "./fellows";
import personnel from "./personnel";
import press from "./press";
import projects from "./projects";
import publications from "./publications";

// *** Try to Create Javascript Modules ***
const modules = {
  "#presse": press,
  "#termine": events,
  "#fellows": fellows,
  "#personal": personnel,
  "#projekte": projects,
  "#publikationen": publications
}

export default {
  load: () => {
    const selectors = Object.keys(modules);
    for (let i = 0; i < selectors.length; i++) {
      const container = $(selectors[i]);
      const Module = modules[selectors[i]];
      if (container.length)
        new Module(container);
    }
  }
}
