/**
* Personal View
* User Input Methods:
*  -select item
*  -starting letters
*  -layout switch (grid/list)
*
* with lazyloading
* + filling page on initial load
* + requesting specific ids instead of "existing" Parameter
* + storing already loaded entrys in init.data
*/
import _ from "lodash";
import {ViewBase, lazyLoad, lazyTest} from "./base";
import {setBackTitle} from "../ieg/backButton";
import templates from "../ieg/templates";

export default class PersonnelView extends ViewBase {
  constructor(element) {
    super({
      context: "personal",
      request: -1
    });

    this.element = element;
    this.list = element.children(".list-group");
    this.currentFilter = "";
    this.currentLayout = "grid";
    this.currentCategory = $("#categorySelector").val();
    this.existing = 0;
    this.first = true;

    this.attachEvents();

    // lazyload
    lazyLoad(() => {
      this.loadData();
    });

    this.setLetters();
    this.loadData();
  }

  attachEvents() {
    $(".letter:not(.disabled)").click((event) => {
      const target = event.currentTarget;
      target.classList.toggle("active");

      if (target.classList.contains("active")) {
        _.each($(".letter:not(.temp)"), (element) => { // radio button
          if (element !== target)
            element.classList.remove("active");
        });

        this.currentFilter = $(target).text().toLowerCase(); // enable filter
      } else {
        this.currentFilter = ""; // disable filter
      }
      this.controller.handleUpdate({ type:this.controller.RESET }); // rerender
      this.controller.handleUpdate({ type: this.controller.UPDATE });
      this.loadData();
    });

    // click on viewButtons
    $("#viewButtons .btn").click((event) => {
      $(event.currentTarget).addClass("active").siblings().removeClass("active");
      const isGrid = (event.currentTarget.id === "gridBtn");
      $(this).addClass("active").siblings().removeClass("active");
      this.currentLayout = isGrid ? "grid" : "list";
      this.list[ isGrid ? "addClass" : "removeClass" ]("gridLayout");
      this.controller.handleUpdate({type: this.controller.RESET}); // rerender
      this.controller.handleUpdate({type: this.controller.UPDATE});
      this.loadData();
    });

    // select category
    $("#categorySelector").change((event) => {
      const $target = $(event.currentTarget);
      this.currentCategory = $target.val();
      this.currentFilter = "";
      this.setLetters();
      this.controller.handleUpdate({ type: this.controller.RESET }); // rerender
      this.controller.handleUpdate({ type: this.controller.UPDATE });
      this.loadData();
    });

    // click details link -> save for back button
    this.list.on("click", ".name", () => {
      setBackTitle($("#contentTitle").text());
    });
  }

  letterFilter(element) {
    return (this.currentFilter === "" || this.currentFilter === element.letter);
  }

  categoryFilter(element) {
    return (this.currentCategory === "all" && element.categories.indexOf("E") === -1 ||
      element.categories.indexOf(this.currentCategory) != -1);
  }

  // set Letter States
  setLetters() {
    const letters = _(init.data).filter(this.categoryFilter.bind(this)).map("letter").value();

    // reset letters
    $(".letter", this.element).addClass("disabled").children(".active").removeClass("active");

    // enable letters
    _.each(letters, (letter) => {
      const letterIndex = letter.convertUmlauts().charCodeAt(0) - "a".charCodeAt(0);
      $(".letter", this.element)[letterIndex].classList.remove("disabled");
    });
  }

  // create List-Group-Items from Templates
  renderItems(data) {
    _.each(data, (element) => {
      element.locale = locale;
      const item = templates[this.currentLayout === "grid" ? "personalgrid" : "personallist" ](element);
      this.list.append(item);
    });
  }

  loadData() {
    const count = 9;
    const data = _(init.data)
    .filter(this.categoryFilter.bind(this))
    .filter(this.letterFilter.bind(this))
    .value().splice(this.existing, count);

    const idsToLoad = [];
    const immediateRender = [];
    this.existing += data.length; // set count of already loaded items

    _.each(data, (element) => {
      if (element.lastName === undefined) {
        idsToLoad.push(element.id);
      } else {
        immediateRender.push(element);
      }
    });

    this.renderItems(immediateRender);
    if (idsToLoad.length > 0) {
      this.controller.handleUpdate({type:this.controller.REQUEST_DATA, options:{
        "ids[]": idsToLoad
      }});
    } else { //if we don't have to wait for data continue lazyloading right away
      this.controller.clearBusy();

      if (this.first && immediateRender.length && lazyTest()) {
        this.loadData();
      } else {
        this.first = false;
      }
    }
  }

  // ----- Define Renderer -----
  render(params) {
    const vars = _.extend({ // defaults
      data: [],
      clear: false,
      reset: false
    }, params);

    if (vars.clear) {
      this.list.html("");
      this.existing = 0;
      // fill screen on next redraw
      this.first = true;
    }

    // insert data into init object for reuse
    const toDraw = [];
    _.each(vars.data, (element) => {
      const storeElement = _.extend(_.find(init.data, (item) => {
        return (item.id === element.id);
      }), element);
      toDraw.push(storeElement);
    });

    this.renderItems(toDraw);
    this.controller.clearBusy();

    if (this.first && vars.data.length && lazyTest()) {
      console.log(this.list.data.length);
      this.loadData();
    } else {
      this.first = false;
    }
  }
}
