import _ from "lodash";
import "../libs/slider";

const frontPage = function(){
  var frontPage = $("#frontPage");
  if(frontPage.length === 0){ return; }


  // *** Create NewsBox Sliders ***
  _.each($(".newsBox", frontPage), function(box){
    var container= $(".slider", $(box));
    var rightBtn= $(".right", $(box));
    var leftBtn= $(".left", $(box));

    container.on("update", function(event, data){
      rightBtn[ data.right ? "addClass" : "removeClass" ]("disabled");
      leftBtn[ data.left ? "addClass" : "removeClass" ]("disabled");
    });

    var slider= fullWidthSlider(container[0]);

    rightBtn.click(function(){ slider.next(); });
    leftBtn.click(function(){ slider.prev(); });
  });

  // *** Create FrontPage Carousel ***
  var frontPageCarousel = carousel($("#carouselContainer", frontPage)[0]);

  // Interval function which auto-circles the carousel
  var createRunner= function(startIndex){
    return setInterval((function(){
      var i= startIndex;
      var length= frontPageCarousel.getLength();
      return function(){
        console.log("frontPage interval");
        i= (i+1) % length;
        frontPageCarousel.goTo(i);
      };
    }()), 10000);
  };
 var interval= createRunner(0);

  var indicators= $(".indicators > p", frontPage);
  for(var i=0; i<frontPageCarousel.getLength(); i++){
    indicators.append("<span class='circle'></span> ");
  }

  $(indicators.children()[0]).addClass("active");
  indicators.on("click", ".circle", function(){
    frontPageCarousel.goTo($(this).index());
  });

  $("#carouselContainer", frontPage).on("update", function(event, data){
    $(indicators.children()[data.index]).addClass("active").siblings(".active").removeClass("active");
    clearInterval(interval);
    interval= createRunner(data.index);
  });
};

export default frontPage;
