;(function() {
  var undefined;

  var objectTypes = {
    'function': true,
    'object': true
  };

  var root = (objectTypes[typeof window] && window) || this;

  var freeExports = objectTypes[typeof exports] && exports && !exports.nodeType && exports;

  var freeModule = objectTypes[typeof module] && module && !module.nodeType && module

  var moduleExports = freeModule && freeModule.exports === freeExports && freeExports;

  var freeGlobal = objectTypes[typeof global] && global;
  if (freeGlobal && (freeGlobal.global === freeGlobal || freeGlobal.window === freeGlobal)) {
    root = freeGlobal;
  }

  var _ = root._;

  var templates = {};

  templates['backButton'] = function(data) {
    var __t, __p = '', __e = _.escape;
    __p += '<div class="back">\n  <a href="javascript:history.back()"><p class="icon icon-chevron-left"></p>' +
    ((__t = ( locale.backTo+" "+data.text  )) == null ? '' : __t) +
    '</a>\n</div>';
    return __p
  };

  templates['busyIndicator'] = function(data) {
    var __t, __p = '', __e = _.escape;
    __p += '<div class="busyIndicator">\n  <img src="../assets/images/ajax-loader2.gif" alt="">\n</div>';
    return __p
  };

  templates['calendar'] = function(data) {
    var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
    function print() { __p += __j.call(arguments, '') }
    __p += '<div class="head">\n  <div class="prev icon icon-chevron-left">\n  </div><div class="title">' +
    ((__t = ( data.title)) == null ? '' : __t) +
    '\n  </div><div class="next icon icon-chevron-right"></div>\n</div>\n\n<div class="dateContainer clearfix">\n';
     if(data.layout === "years"){
    __p += '\n  ';
      _.each(data.years, function(item){
    __p += '\n    <div class="year ' +
    ((__t = ( item.properties )) == null ? '' : __t) +
    '">' +
    ((__t = ( item.name  )) == null ? '' : __t) +
    '</div>\n  ';
      });  ;
    __p += '\n';
     }else if(data.layout === "months"){
    __p += '\n  ';
      _.each(data.months, function(item){
    __p += '\n    <div class="month ' +
    ((__t = ( item.properties )) == null ? '' : __t) +
    '">' +
    ((__t = ( item.name  )) == null ? '' : __t) +
    '</div>\n  ';
      });  ;
    __p += '\n';
     }else{
    __p += '\n  ';
      _.each(data.dayTitles, function(item){
    __p += '\n    <div class="dayTitle ' +
    ((__t = ( item.properties )) == null ? '' : __t) +
    '">' +
    ((__t = ( item.name  )) == null ? '' : __t) +
    '</div>\n  ';
      });  ;
    __p += '\n  ';
      _.each(data.days, function(item){
    __p += '\n    <div class="day ' +
    ((__t = ( item.properties )) == null ? '' : __t) +
    '">' +
    ((__t = ( item.name  )) == null ? '' : __t) +
    '</div>\n  ';
      });  ;
    __p += '\n';
     }
    __p += '\n</div>';
    return __p
  };

  templates['fellowListHeader'] = function(data) {
    var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
    function print() { __p += __j.call(arguments, '') }

     if(data.grid){
    __p += '\n' +
    ((__t = ( window.locale.sortBy )) == null ? '' : __t) +
    ':\n<span class="sort" data-sortBy="name">' +
    ((__t = ( window.locale.name )) == null ? '' : __t) +
    '</span>\n<span class="sort" data-sortBy="year">' +
    ((__t = ( window.locale.year )) == null ? '' : __t) +
    '</span>\n<span class="sort" data-sortBy="country">' +
    ((__t = ( window.locale.country )) == null ? '' : __t) +
    '</span>\n';
     }else{
    __p += '\n<div class="sort col-xs-7" data-sortBy="name">' +
    ((__t = ( window.locale.name )) == null ? '' : __t) +
    '<span class="fa ' +
    ((__t = ( data.nameIcon )) == null ? '' : __t) +
    '"></span></div>\n<div class="sort col-xs-2" data-sortBy="year">' +
    ((__t = ( window.locale.year )) == null ? '' : __t) +
    '<span class="fa ' +
    ((__t = ( data.yearIcon )) == null ? '' : __t) +
    '"></span></div>\n<div class="sort col-xs-3" data-sortBy="country">' +
    ((__t = ( window.locale.country )) == null ? '' : __t) +
    '<span class="fa ' +
    ((__t = ( data.countryIcon )) == null ? '' : __t) +
    '"></span></div>\n';
     }

    return __p
  };

  templates['fellowTags'] = function(data) {
    var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
    function print() { __p += __j.call(arguments, '') }

      _.each(data.filters, function(item){
    __p += '<span class="tag" data-value="' +
    ((__t = ( item.value )) == null ? '' : __t) +
    '">' +
    ((__t = ( item.name )) == null ? '' : __t) +
    '<span class="icon icon-times"></span></span>';
     }); ;

    return __p
  };

  templates['fellowgrid'] = function(data) {
    var __t, __p = '', __e = _.escape;
    __p += '<div class="col-sm-6 col-md-4 list-group-item">\n  <div class="borderWrap">\n    <div class="name"><a href="' +
    ((__t = ( data.url )) == null ? '' : __t) +
    '">' +
    ((__t = ( data.name )) == null ? '' : __t) +
    '</a></div>\n    <div class="year">' +
    ((__t = ( data.year )) == null ? '' : __t) +
    '</div>\n    <div class="funding">' +
    ((__t = ( data.funding )) == null ? '' : __t) +
    '</div>\n    <div class="country">' +
    ((__t = ( data.country )) == null ? '' : __t) +
    '</div>\n  </div>\n</div>';
    return __p
  };

  templates['fellowlist'] = function(data) {
    var __t, __p = '', __e = _.escape;
    __p += '<div class="list-group-item row">\n  <div class="imgContainer col-sm-3 col-md-2">\n    <img src="' +
    ((__t = ( data.image !== "" ? data.image : '../assets/images/head.jpg' )) == null ? '' : __t) +
    '" alt="">\n  </div>\n  <div class="col-sm-4 col-md-5">\n    <div class="name"><a href="' +
    ((__t = ( data.url )) == null ? '' : __t) +
    '">' +
    ((__t = ( data.name )) == null ? '' : __t) +
    '</a></div>\n    <div class="funding">' +
    ((__t = ( data.funding )) == null ? '' : __t) +
    '</div>\n  </div>\n  <div class="col-sm-2">\n    <div class="year">' +
    ((__t = ( data.year )) == null ? '' : __t) +
    '</div>\n  </div>\n  <div class="col-sm-3">\n    <div class="country">' +
    ((__t = ( data.country )) == null ? '' : __t) +
    '</div>\n  </div>\n</div>';
    return __p
  };

  templates['footerBox'] = function(data) {
    var __t, __p = '', __e = _.escape;
    __p += '<div class="col-sm-3">\r\n  <p class="title">' +
    ((__t = ( data.title )) == null ? '' : __t) +
    '</p>\r\n  <ul class="nav"></ul>\r\n</div>';
    return __p
  };

  templates['personalgrid'] = function(data) {
    var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
    function print() { __p += __j.call(arguments, '') }
    __p += '<div class="col-sm-6 col-md-4 list-group-item">\n  <div class="borderWrap">\n    <div class="name"><a href="' +
    ((__t = ( data.detailsUrl )) == null ? '' : __t) +
    '">' +
    ((__t = ( data.title )) == null ? '' : __t) +
    ' ' +
    ((__t = ( data.firstName )) == null ? '' : __t) +
    ' ' +
    ((__t = ( data.lastName )) == null ? '' : __t) +
    '</a></div>\n    <div class="description">' +
    ((__t = ( data.description )) == null ? '' : __t) +
    '</div>\n    <div class="phone">';
     if(data.phone !== ""){
    __p += 'tel ' +
    ((__t = ( data.phone )) == null ? '' : __t);
     }
    __p += '</div>\n    ';
     if(data.email) {
    __p += '\n    <div class="email"><a href="mailto:' +
    ((__t = ( data.email )) == null ? '' : __t) +
    '">\n      ' +
    ((__t = ( data.locale.writeEmail )) == null ? '' : __t) +
    '\n      <span class="icon icon-envelope-o pull-right"></span>\n    </a></div>\n    ';
     } else {
    __p += '\n    <div class="noemail">\n    </div>\n    ';
     }
    __p += '\n  </div>\n</div>\n';
    return __p
  };

  templates['personallist'] = function(data) {
    var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
    function print() { __p += __j.call(arguments, '') }
    __p += '<div class="row clearfix list-group-item">\n  <div class="col-sm-2 hidden-xs imgWrap"><img src="' +
    ((__t = ( data.image !== "" ? data.image : '../assets/images/head.jpg' )) == null ? '' : __t) +
    '"/></div>\n  <div class="col-sm-5">\n    <div class="name"><a href="' +
    ((__t = ( data.detailsUrl )) == null ? '' : __t) +
    '">' +
    ((__t = ( data.title )) == null ? '' : __t) +
    ' ' +
    ((__t = ( data.firstName )) == null ? '' : __t) +
    ' ' +
    ((__t = ( data.lastName )) == null ? '' : __t) +
    '</a></div>\n    <div class="descr hidden-xs">' +
    ((__t = ( data.description )) == null ? '' : __t) +
    '</div>\n  </div>\n  <div class="col-sm-5">\n    <div class="location hidden-xs">' +
    ((__t = ( data.location )) == null ? '' : __t) +
    '</div>\n    <div class="phone">';
     if(data.phone !== ""){
    __p += 'tel ' +
    ((__t = ( data.phone )) == null ? '' : __t) +
    ' ';
     }
    __p += '</div>\n    <div class="fax">';
     if(data.fax !== ""){
    __p += 'fax ' +
    ((__t = ( data.fax )) == null ? '' : __t) +
    ' ';
     }
    __p += '</div>\n    ';
     if(data.email) {
    __p += '\n    <div class="email"><a href="mailto:' +
    ((__t = ( data.email )) == null ? '' : __t) +
    '">\n      <span class="icon icon-envelope-o"></span>\n      ' +
    ((__t = ( data.locale.writeEmail )) == null ? '' : __t) +
    '\n    </a></div>\n    ';
     }
    __p += '\n  </div>\n</div>\n';
    return __p
  };

  templates['presse'] = function(data) {
    var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
    function print() { __p += __j.call(arguments, '') }
    __p += '<div class="list-group-item">\n  <div class="date">' +
    ((__t = ( locale.formatShortDate(parseDate(data.date)) )) == null ? '' : __t) +
    '</div>\n  <div class="text">' +
    ((__t = ( data.text )) == null ? '' : __t) +
    '</div>\n  \n  ';
      if(data.moreText !== ""){
    __p += '\n  <div class="more">\n    <div class="title"><a href="#"><span class="icon icon-plus"></span>weitere Informationen</a></div>\n    <div class="text">' +
    ((__t = ( data.moreText )) == null ? '' : __t) +
    '</div>\n  </div>\n  ';
      }
    __p += '\n  \n  ';
      _.each(data.download_links, function(item){
    __p += '\n  <div class="downloadLink"><a href="' +
    ((__t = ( item.href )) == null ? '' : __t) +
    '" target="_blank"><span class="icon icon-file"></span>' +
    __e( item.name  ) +
    '</a></div>\n  ';
      });  ;
    __p += '\n  \n  ';
      _.each(data.internal_links, function(item){
    __p += '\n    <div class="internalLink"><a href="' +
    ((__t = ( item.href )) == null ? '' : __t) +
    '"><span class="icon icon-align-left"></span>' +
    __e( item.name  ) +
    '</a></div>\n  ';
      });  ;
    __p += '\n  \n  ';
      _.each(data.external_links, function(item){
    __p += '\n    <div class="externalLink"><a href="' +
    ((__t = ( item.href )) == null ? '' : __t) +
    '" target="_blank"><span class="icon icon-external-link"></span>' +
    __e( item.name  ) +
    '</a></div>\n  ';
      });  ;
    __p += '\n</div>';
    return __p
  };

  templates['projekte'] = function(data) {
    var __t, __p = '', __e = _.escape;
    __p += '<div class="list-group-item clearfix">\n  <!--<div class="col-sm-3"><a href="' +
    ((__t = ( data.url )) == null ? '' : __t) +
    '" class="title"><img src="' +
    ((__t = ( data.image )) == null ? '' : __t) +
    '"></a></div>-->\n  <div class="col-sm-12">\n    <h4 class="title"><a href="' +
    ((__t = ( data.url )) == null ? '' : __t) +
    '">' +
    ((__t = ( data.title )) == null ? '' : __t) +
    '</a></h4>\n    <p class="description">' +
    ((__t = ( data.description )) == null ? '' : __t) +
    '</p>\n  </div>\n</div>';
    return __p
  };

  templates['publikationen'] = function(data) {
    var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
    function print() { __p += __j.call(arguments, '') }
    __p += '<div class="row list-group-item">\n  ';

      // Artikel einordnen
      var inPublication= data["in"] ? true : false;
      var inMagazine= data.type === "Zeitschriftenaufsatz";
    __p += '\n  \n  <!-- Linke Seite Oben -->\n  <div class="col-sm-8">\n    <div class="authors">\n      ' +
    ((__t = ( data.authors.join(", ") )) == null ? '' : __t) +
    ' \n      ' +
    ((__t = ( (data.editor !== "") ? data.editor+" (Hg.)" : "" )) == null ? '' : __t) +
    '\n    </div>\n    <div class="title"><a href="' +
    ((__t = ( data.url )) == null ? '' : __t) +
    '">' +
    ((__t = ( data.title )) == null ? '' : __t) +
    '</a></div>\n    <div class="subtitle">' +
    ((__t = ( data.subtitle )) == null ? '' : __t) +
    '</div>\n    \n    <!-- bei Zeitschriften unten -->\n    ';
     if(!inMagazine){
    __p += '\n    <div class="series">\n      ' +
    ((__t = ( (data.seriestitle && data.seriestitle !== "")? data.seriestitle + ", " : "" )) == null ? '' : __t) +
    '\n      ' +
    ((__t = ( (data.volume && data.volume !== "")? data.volume : "" )) == null ? '' : __t) +
    '\n    </div>\n    ';
     }
    __p += '\n  </div>\n  \n  <!-- Rechte Seite Oben -->\n  <div class="col-sm-4">\n    <div class="year">\n      ' +
    ((__t = ( (data.location && data.location !== "")? data.location + ": " : "" )) == null ? '' : __t) +
    '\n      ' +
    ((__t = ( (data.publisher && data.publisher !== "")? data.publisher + ", " : "" )) == null ? '' : __t) +
    '\n      ' +
    ((__t = ( data.year )) == null ? '' : __t) +
    ' \n    </div>\n    \n    <!-- bei Zeitschriften steht seitenzahl unten   -->\n    ';
     if(!inMagazine){
    __p += '\n    ';
     if(data.pages && data.pages !== ""){
    __p += '<div class="pages">' +
    ((__t = ( locale.pagesPrefix+" "+data.pages )) == null ? '' : __t) +
    '</div> ';
     }
    __p += '\n    ';
     if(data.isbn && data.isbn !== ""){
    __p += '<div class="isbn">ISBN ' +
    ((__t = ( data.isbn )) == null ? '' : __t) +
    '</div> ';
     }
    __p += '\n    ';
     }
    __p += '\n  </div>\n  \n  <!-- in Publikation -->\n  ';
     if(inPublication){ var inPub= data["in"]; ;
    __p += '\n  <div class="col-sm-8 in">\n    <div>in:</div>\n    <div class="authors">\n      ' +
    ((__t = ( (inPub.editor !== "") ? inPub.editor+" (Hg.)" : "")) == null ? '' : __t) +
    '\n    </div>\n    <div class="title"><a href="' +
    ((__t = ( inPub.url )) == null ? '' : __t) +
    '">' +
    ((__t = ( inPub.title )) == null ? '' : __t) +
    '</a></div>\n  </div>\n  \n  <div class="col-sm-4 in">\n    <br>\n    <div class="year">\n      ' +
    ((__t = ( (inPub.location && inPub.location !== "")? inPub.location+": " : "" )) == null ? '' : __t) +
    '\n      ' +
    ((__t = ( inPub.publisher )) == null ? '' : __t) +
    '\n    </div>\n    \n    ';
     if(inPub.pages && inPub.pages !== ""){
    __p += '<div class="pages">' +
    ((__t = ( locale.pagesPrefix+" "+inPub.pages )) == null ? '' : __t) +
    '</div> ';
     }
    __p += '\n    ';
     if(inPub.isbn && inPub.isbn !== ""){
    __p += '<div class="isbn">ISBN ' +
    ((__t = ( inPub.isbn )) == null ? '' : __t) +
    '</div> ';
     }
    __p += '\n  </div>\n  ';
     }
    __p += '\n  \n  <!-- in Zeitschrift  -->\n  ';
     if(inMagazine){
    __p += '\n  <div class="col-sm-8 in">\n    <div>in:</div>\n    <div>\n      ' +
    ((__t = ( data.paper )) == null ? '' : __t) +
    '\n      ';
     if(data.volume!=="" || data.number!==""){
    __p += '\n      (' +
    ((__t = ( (data.volume!=="") ? data.volume+", " : "" )) == null ? '' : __t) +
    '' +
    ((__t = ( data.number )) == null ? '' : __t) +
    ')\n      ';
     }
    __p += '\n    </div>\n  </div>\n  \n  <div class="col-sm-4 in">\n    <br>\n    ';
     if(data.pages && data.pages !== ""){
    __p += '<div class="pages">' +
    ((__t = ( locale.pagesPrefix+" "+data.pages )) == null ? '' : __t) +
    '</div> ';
     }
    __p += '\n    ';
     if(data.issn && data.issn !== ""){
    __p += '<div class="isbn">ISSN ' +
    ((__t = ( data.issn )) == null ? '' : __t) +
    '</div> ';
     }
    __p += '\n  </div>\n  ';
     }
    __p += '\n</div>';
    return __p
  };

  templates['tag'] = function(data) {
    var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
    function print() { __p += __j.call(arguments, '') }

     if(data.wrap){
    __p += '\n';
     _.each(data.tags, function(item){
    __p += '\n<div>\n  <span class="tag selected">' +
    ((__t = ( item )) == null ? '' : __t) +
    '<span class="icon icon-times"></span></span>\n</div>\n';
     }); }else{
    __p += '\n';
      _.each(data.tags, function(item){
    __p += '<span class="tag">' +
    ((__t = ( item )) == null ? '' : __t) +
    '<span class="icon icon-times"></span></span>';
     }); ;
    __p += '\n';
     }

    return __p
  };

  templates['termine'] = function(data) {
    var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
    function print() { __p += __j.call(arguments, '') }
    __p += '<div class="list-group-item">\n<div class="tags">\n  ';
     if(data.startDate === data.endDate){
            var start= parseDate(data.startDate);
    __p += '\n    <span class="date">' +
    ((__t = ( window.locale.formatShortDate(start) )) == null ? '' : __t) +
    '\n    ';
     var time=data.time.split(":");
    __p += '\n    </span><span class="time">' +
    ((__t = ( time[0]+":"+time[1] )) == null ? '' : __t) +
    '\n  ';
     }else{
            var start= parseDate(data.startDate);
            var end= parseDate(data.endDate);
    __p += '\n    <span class="date">' +
    ((__t = ( window.locale.formatShortDate(start) +" - "+ window.locale.formatShortDate(end) )) == null ? '' : __t) +
    '\n  ';
     }
    __p += '\n  </span>';
      _.each(data.tags, function(item){
    __p += '<span class="tag">' +
    ((__t = ( item )) == null ? '' : __t) +
    '</span>';
     }); ;
    __p += '\n</div>\n<div class="text">' +
    ((__t = ( data.text )) == null ? '' : __t) +
    '</div>\n  \n';
      if(data.moreText !== ""){
    __p += '\n<div class="more">\n  <div class="title"><a href="#"><span class="icon icon-plus"></span>weitere Informationen</a></div>\n  <div class="text">' +
    ((__t = ( data.moreText )) == null ? '' : __t) +
    '</div>\n</div>\n';
      }
    __p += '\n  \n';
      _.each(data.download_links, function(item){
    __p += '\n  <div class="downloadLink"><a href="' +
    ((__t = ( item.href )) == null ? '' : __t) +
    '" target="_blank"><span class="icon icon-file"></span>' +
    __e( item.name  ) +
    '</a></div>\n';
      });  ;
    __p += '\n  \n';
      _.each(data.internal_links, function(item){
    __p += '\n  <div class="internalLink"><a href="' +
    ((__t = ( item.href )) == null ? '' : __t) +
    '"><span class="icon icon-align-left"></span>' +
    __e( item.name  ) +
    '</a></div>\n';
      });  ;
    __p += '\n  \n';
      _.each(data.external_links, function(item){
    __p += '\n  <div class="externalLink"><a href="' +
    ((__t = ( item.href )) == null ? '' : __t) +
    '" target="_blank"><span class="icon icon-external-link"></span>' +
    __e( item.name  ) +
    '</a></div>\n';
      });  ;
    __p += '\n</div>';
    return __p
  };

  if (typeof define == 'function' && typeof define.amd == 'object' && define.amd) {
    define(['lodash'], function(lodash) {
      _ = lodash;
      lodash.templates = lodash.extend(lodash.templates || {}, templates);
    });
  } else if (freeExports && freeModule) {
    _ = require('lodash');
    if (moduleExports) {
      (freeModule.exports = templates).templates = templates;
    } else {
      freeExports.templates = templates;
    }
  } else if (_) {
    _.templates = _.extend(_.templates || {}, templates);
  }
}.call(this));
