import _ from "lodash";
import templates from "../ieg/templates";

const busyIndicator = {
  main: $("#main"),
  html: $(templates.busyIndicator({})),
  ref: null,
  show: function() {
    if (this.ref === null) {
      this.ref = this.html.appendTo(this.main);
    }
  },
  hide: function() {
    if (this.ref !== null) {
      this.ref.remove();
      this.ref = null;
    }
  }
};


/**
 * Tests if lazyLoad Condition is met.
 * @param {Function} callback executed if condition is met
 **/
let busy = true; // until first render
const lazyTest = function(callback) {
  const diff = $("footer").height() + 300;
  return (!busy && ($(document).scrollTop() + $(window).height() > $(document).height() - diff));
}

const lazyLoad = function(callback) {
  const throttle = _.throttle(() => {
    if (lazyTest()) {
      busy = true;
      callback();
    }
  }, 200);
  $(window).scroll(throttle);
}

/**
 * Interacts with server side over ajax. Automatically updates View
 * when receiving data.
 * @param {Object} [defaultParams] Default Post Parameters
 **/
class ControllerBase {
  constructor(defaultParams, view) {
    this.UPDATE = 0;
    this.RESET = 1;
    this.REQUEST_DATA = 2;
    this.CHANGE_PARAMS = 3;

    this.data = [];
    this.defaultParams = defaultParams;
    this.view = view; // reference to view for triggering render events
    this.stopRequests = false;
  }

  // default ajax call
  ajax(params, callback) {
    var vars = _.extend({ // defaults
      existing: this.data.length,
      lang: window.locale.lang,
      context: "",
      request: 0
    }, params);

    console.log("requestParams", vars);

    $.ajax({
      url: window.init.ajaxUrl,
      type: "POST",
      data: vars

    }).done(function(response) {
      console.log("responseStatus", response.status); //debug
      callback(response);

    }).error(function(request, status, error) {
      console.error(request, status, error);
    });
  }

  clearBusy() {
    busy = false;
  }

  clear() {
    this.data = [];
    this.view.render({
      data: this.data,
      reset: true
    });
  }

  // handles user input
  handleUpdate(update) {
    const vars = _.extend(this.defaultParams, update.options);

    if (update.type === this.UPDATE) {
      this.view.render({
        data: this.data,
        clear: true
      });

    } else if(update.type === this.RESET) {
      this.clear();

    } else if(update.type === this.REQUEST_DATA) {
      if (this.stopRequests)
        return

      busyIndicator.show();
      this.ajax(vars, (response) => {
        if (response.data.length === 0)
          this.stopRequests = true;

        this.data = this.data.concat(response.data);
        busyIndicator.hide();
        this.view.render(response);
      });

    } else if(update.type === this.CHANGE_PARAMS) {
      this.data = [];
      busyIndicator.show();
      this.ajax(vars, (response) => {
        this.data = response.data;
        this.stopRequests = false;
        busyIndicator.hide();
        this.view.render(_.extend(response, {
          reset: true,
          clear: true
        }));
      });
    }
  }
}


/**
 * Base Class for Views
 **/
class ViewBase {
  constructor(defaults = {}) {
    this.controller = new ControllerBase(defaults, this);
  }

  // override
  render() {}
}

export {ViewBase, lazyTest, lazyLoad};
