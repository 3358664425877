;
import _ from "lodash";
import TweenLite from "gsap/src/uncompressed/TweenLite";

var sliderBase= function(container, options){
  "use strict";

  var that= {
    container: $(container),
    options: options,
    index: 0,

    // check browser capabilities
    browser: {
      addEventListener: !!window.addEventListener,
      touch: ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch,
      transforms: (function(temp) {
        var props = ['transform', 'msTransform', 'MozTransform', 'OTransform', 'WebkitTransform'];
        for ( var i in props ){
          if (temp.style[ props[i] ] !== undefined){ return true; }
        }
        return false;
      })(document.createElement('swipe'))
    },

    // animate to index
    animate: function(to, time){
      this.index= to;
      this.translate(0, time);
      this.container.trigger("update", {
        index: this.index,
        left: (this.index === 0),
        right: (this.index === this.length-1)
      });
    },
    translate: function(diff, time){},
    goTo: function(to){
      if(to >= 0 && to < this.length){
        this.animate(+to, options.transitionTime);
      }
    },
    next: function(){
      this.goTo(this.index+1);
    },
    prev: function(){
      this.goTo(this.index-1);
    }
  };

  console.log("Browser Transform Support:", that.browser.transforms);
  // set up self-referencing members
  that.element= container.children[0];
  that.slides= that.element.children;
  that.length= that.slides.length;
  that.width= that.container.width();

  return that;
};

// adds touch events to slider object
var addTouch= function(that){

  // event vars
  var start = {};
  var delta = {};
  var isScrolling;

  var onStart= function(event){
      var touches = event.touches[0];

      // initial position and time
      start = {
        x: touches.pageX,
        y: touches.pageY,
        time: +new Date()
      };

      // used for testing first move event
      isScrolling = undefined;

      // reset delta and end measurements
      delta = {
        x: 0,
        y: 0
      };

      // attach touchmove and touchend listeners
      that.element.addEventListener('touchmove', that.handleTouch, false);
      that.element.addEventListener('touchend', that.handleTouch, false);
  };

  var onMove= function(event){
    // ensure swiping with one touch and not pinching
    if ( event.touches.length > 1 || event.scale && event.scale !== 1){ return; }

    var touches = event.touches[0];

    // measure change in x and y
    delta = {
      x: touches.pageX - start.x,
      y: touches.pageY - start.y
    };

    // determine if scrolling test has run - one time test
    if ( typeof isScrolling === 'undefined') {
      isScrolling = !!( isScrolling || Math.abs(delta.x) < Math.abs(delta.y) );
    }

    // if user is not trying to scroll vertically
    if (!isScrolling) {

      // prevent native scrolling
      event.preventDefault();

      // increase resistance if first or last slide
      delta.x =
        delta.x /
          ( (!that.index && delta.x > 0 ||         // if first slide and sliding left
             that.index === length - 1 &&    // or if last slide and sliding right
             delta.x < 0                      // and if sliding at all
          ) ?
          ( Math.abs(delta.x) / that.width + 1 )   // determine resistance level
          : 1 );                              // no resistance if false

      // translate 1:1
      that.translate(delta.x, 0);
    }
  };

  var onEnd= function(event){
    var duration = +new Date() - start.time;

    // determine if slide attempt triggers next/prev slide
    var isValidSlide =
      Number(duration) < 250 &&         // if slide duration is less than 250ms
      Math.abs(delta.x) > 20 ||        // and if slide amt is greater than 20px
      Math.abs(delta.x) > that.width/2;     // or if slide amt is greater than half the width

    // determine if slide attempt is past start and end
    var isPastBounds =
      !that.index && delta.x > 0 ||                      // if first slide and slide amt is greater than 0
       that.index === that.length - 1 && delta.x < 0;   // or if last slide and slide amt is less than 0

    // if not scrolling vertically
    if (!isScrolling) {
      if (isValidSlide && !isPastBounds) {
        //determine direction
        if(delta.x < 0){
          that.animate(that.index +1, that.options.transitionTime);
        }else{
          that.animate(that.index -1, that.options.transitionTime);
        }

      // snap back to current slide
      }else{
        that.animate(that.index, that.options.transitionTime);
      }
    }

    // kill touchmove and touchend event listeners until touchstart called again
    that.element.removeEventListener('touchmove', that.handleTouch, false);
    that.element.removeEventListener('touchend', that.handleTouch, false);
  };

  // setup event capturing
  that.handleTouch= function(event){
    switch (event.type) {
      case 'touchstart': onStart(event); break;
      case 'touchmove': onMove(event); break;
      case 'touchend': onEnd(event); break;
    }
  };

  // Create Event listener
  if(that.browser.addEventListener && that.browser.touch){
    that.element.addEventListener('touchstart', that.handleTouch, false);
  }

  return that;
};

// unbinds touch event handlers and removes touch property
var removeTouch= function(that){
  if(that.browser.addEventListener && that.browser.touch){
    that.element.removeEventListener('touchstart', that.handleTouch, false);
  }

  delete that.handleTouch;
  return that;
};

// Project Slider
window.projectSlider = function(container, config){
  "use strict";


  // default config values
  var options = _.extend({
    width: 200,
    transitionTime: 0.3,
    disableScroll: false
  }, config);

  var base= sliderBase(container, options);
  var element= base.element;
  var width= options.width;
  var length= base.slides.length;
  var containerOffset, offset;

  // moves the slider to current index + parametric offset
  var translate = function(diff, time){
    offset = diff;
    if(base.browser.transforms){
      TweenLite.to(element, time, {x: ((-width * base.index)+ diff + containerOffset), ease:Power3.easeOut});

    }else{
      TweenLite.to(element, time, {left: ((-width * base.index)+ diff + containerOffset), ease:Power3.easeOut});
    }
  };

  // animate to index
  base.animate = function(to, time){
    base.index= to;
    translate(0, time);

    $(container).trigger("update", {
      index: base.index,
      left: (base.index === 0),
      right: (base.index === length-1)
    });
  };

  // event vars
  var start = {};
  var delta = {};
  var isScrolling;
  var filter= 0.5;
  var a= -4000;

  // setup event capturing
  var events = {
    handleEvent: function(event) {
      switch (event.type) {
        case 'touchstart': this.start(event); break;
        case 'touchmove': this.move(event); break;
        case 'touchend': this.end(event); break;
      }
    },
    start: function(event) {
      var touches = event.touches[0];

      // initial position and time
      start = {
        x: touches.pageX,
        y: touches.pageY,
        time: +new Date()
      };

      // used for testing first move event
      isScrolling = undefined;

      // reset delta and end measurements
      delta = {
        v: 0,
        x: 0,
        y: 0,
        time: start.time
      };

      // attach touchmove and touchend listeners
      element.addEventListener('touchmove', this, false);
      element.addEventListener('touchend', this, false);
    },
    move: function(event) {
      // ensure swiping with one touch and not pinching
      if ( event.touches.length > 1 || event.scale && event.scale !== 1){ return; }

      if (options.disableScroll){ event.preventDefault(); }

      var touches = event.touches[0];

      // measure change in x and y
      delta = {
        // calculate exponential moving average of touch velocity
        v: (filter * delta.v) + ((1-filter) * (touches.pageX - start.x - delta.x) / (+new Date() - delta.time)),
        x: touches.pageX - start.x,
        y: touches.pageY - start.y,
        time: +new Date()
      };

      // determine if scrolling test has run - one time test
      if ( typeof isScrolling === 'undefined') {
        isScrolling = !!( isScrolling || Math.abs(delta.x) < Math.abs(delta.y) );
      }

      // if user is not trying to scroll vertically
      if (!isScrolling) {

        // prevent native scrolling
        event.preventDefault();

        // increase resistance if first or last slide
        delta.x =
          delta.x /
            ( (!base.index && delta.x > 0 ||         // if first slide and sliding left
               base.index === length - 1 &&    // or if last slide and sliding right
               delta.x < 0                      // and if sliding at all
            ) ?
            ( Math.abs(delta.x) / width + 1 )   // determine resistance level
            : 1 );                              // no resistance if false

        // translate 1:1
        translate(delta.x, 0);
      }
    },
    end: function() {
      var duration = +new Date() - start.time;

      // determine if slide attempt triggers next/prev slide
      var isValidSlide =
            Number(duration) < 250 &&         // if slide duration is less than 250ms
             Math.abs(delta.x) > 20 ||        // and if slide amt is greater than 20px
             Math.abs(delta.x) > width/2;     // or if slide amt is greater than half the width

      // determine if slide attempt is past start and end
      var isPastBounds =
            !base.index && delta.x > 0 ||                      // if first slide and slide amt is greater than 0
             base.index === length - 1 && delta.x < 0;   // or if last slide and slide amt is less than 0

      // if not scrolling vertically
      if (!isScrolling) {
        if (isValidSlide && !isPastBounds) {

          // calculate animation distance based on constant deceleration
          var v= Math.abs(delta.v*800);
          v%= 3000;
          var t= -v / a;
          var dist= a/2*t*t + v*t;
          var newIndex= base.index - Math.floor(Math.abs(delta.x / width) + dist/width) * Math.abs(delta.x)/delta.x;

          if(t<0.25){ t= 0.25; }

          // speed not high enough -> flicking
          if(newIndex === base.index){

            //determine direction
            if(delta.x < 0){
              base.animate(base.index +1, options.transitionTime);
            }else{
              base.animate(base.index -1, options.transitionTime);
            }
          }else{

            //if out of bounds -> limit to border & change animation time accordingly
            if(newIndex> length-1){

              t*= (length-1-base.index) / (newIndex-base.index);
              newIndex= length-1;

            }else if(newIndex < 0){

              t*= (base.index-0) / (base.index-newIndex);
              newIndex= 0;
            }
            base.animate(newIndex, t);
          }

        // snap back to current slide
        }else{
          base.animate(base.index, options.transitionTime);
        }
      }

      // kill touchmove and touchend event listeners until touchstart called again
      element.removeEventListener('touchmove', events, false);
      element.removeEventListener('touchend', events, false);
    }
  };

  // make sure the slider is centered
  var updateOffset = _.debounce(function(){
    containerOffset = (container.getBoundingClientRect().width/2 || container.offsetWidth/2) - (width / 2);
    translate(offset, 0.2);
  }, 250);

  // Set up Css and Eventlisteners
  var setup = function(){
    // init widths
    element.style.width = (length * width) + "px";
    _.each(base.slides, function(slide){
      slide.style.width = width + "px";
    });

    var style= element.style;
    style.webkitBackfaceVisibility =
    style.msBackfaceVisibility =
    style.mozBackfaceVisibility =
    style.backfaceVisibility = "hidden";

    // initial centering
    containerOffset = (container.getBoundingClientRect().width/2 || container.offsetWidth/2) - (width / 2);
    base.index = Math.floor(length / 2);
    translate(0, 0);

    if(base.browser.addEventListener){
      if(base.browser.touch){ element.addEventListener('touchstart', events, false); }
      window.addEventListener("resize", updateOffset);
    }else{
      window.onresize = updateOffset;
    }
    container.style.visibility = "visible";
  };

  setup();

  // exported functionality
  base.unbind= function(){
    //unbinding eventListeners
    console.log("unbinding eventlisteners");
    if(base.browser.addEventListener){
      if(base.browser.touch){ console.log("actually doing something"); element.removeEventListener('touchstart', events); }
      window.removeEventListener("resize", updateOffset);
    }
  };

  return base;
};

// FrontPage Carousel
window.carousel = function(container, config){
  "use strict";

  // default config values
  var options = _.extend({
    transitionTime: 0.5,
    disableScroll: true
  }, config);

  var that= addTouch(sliderBase(container, options)),
  active= {};

  // create animation function
  // @override moves the slider to current index + offset
  that.translate= function(diff, time){
    if(that.index !== active.index){
      // prepare next slide
      var next= $(that.slides[that.index]).css("z-index", "5").css("opacity", "1");

      // on transition finish layer next slide on top
      TweenLite.to(active.slide, time, {opacity:0, onComplete: function(){
      next.css("z-index", "10");
      active.slide.css("z-index", "0");
      active= {slide: next, index: that.index};
    }});
    }
  };

  // Set up CSS
  that.element.style.width= (that.length * 100) + "%";
  _.each(that.slides, function(slide, index){
    $(slide).width((100 / that.length) + "%")
    .css("left", -(index * 100 / that.length) + "%");
  });

  // show first slide
  active.index= that.index= 0;
  active.slide= $(that.slides[that.index])
  .css("opacity", "1").css("z-index", "10");

  container.style.visibility = "visible";

  that.getLength= function(){ return that.length; };
  return that;
};

// Single Element Slider
window.fullWidthSlider = function(container, config){
  "use strict";

  // default config values
  var options = _.extend({
    transitionTime: 0.7,
    disableScroll: true
  }, config);

  var that= addTouch(sliderBase(container, options));

  // create animation function
  // @override moves the slider to current index + offset
  that.translate = function(diff, time){
    if(that.browser.transforms){
      TweenLite.to(that.element, time, {x: (diff-that.index*that.width), ease:Power3.easeOut});
    }else{
      TweenLite.to(that.element, time, {left: -(100*that.index)+"%", ease:Power3.easeOut});
    }
  };

  // scale parent element
  that.element.style.width= (that.length * 100)+"%";

  // scale slide elements
  _.each(that.slides, function(slide, index){
    var s= $(slide);
    s.width((100/that.length) + "%");
  });

  var style= that.element.style;
  style.webkitBackfaceVisibility =
  style.msBackfaceVisibility =
  style.mozBackfaceVisibility =
  style.backfaceVisibility = "hidden";

  // keep translation constant on resize
  $(window).resize(_.throttle(function(){
    that.width= that.container.width();
    that.translate(0,0);
  }, 100));

  that.container.trigger("update", {
    index: that.index,
    left: (that.index === 0),
    right: (that.index === that.length-1)
  });

  container.style.visibility = "visible";

  return that;
};
