/**
* Presse View
* User Input Methods:
*  -yearSelect
*
* with lazyloading
* + filling page on initial load
*/
import _ from "lodash";
import {ViewBase, lazyTest, lazyLoad} from "./base";
import templates from "../ieg/templates";

export default class PressView extends ViewBase {
  constructor(element) {
    super({
      context: "presse",
      request: 5
    });

    this.list = $(".list-group", element);
    this.first = true;

    this.attachEvents();

    // lazyload
    lazyLoad(() => {
      this.controller.handleUpdate({type: this.controller.REQUEST_DATA, options:{
        year: $("#yearSelector").val()
      }});
    });

    // initial request
    this.controller.handleUpdate({type: this.controller.CHANGE_PARAMS, options:{
      year: $("#yearSelector").val()
    }});
  }

  attachEvents() {
    // select year
    $("#yearSelector").change((event) => {
      const $target = $(event.currentTarget);
      this.controller.handleUpdate({type: this.controller.CHANGE_PARAMS, options:{
        year: $target.val()
      }});
    });
  }

  render(params) {
    const vars = _.extend({ // defaults
      data: [],
      clear: false,
      reset: false
    }, params);

    if (vars.reset || vars.clear) {
      this.list.html("");
      this.first = true;
    }

    _.each(vars.data, (element) => {
      var item = templates.presse(element);
      this.list.append(item);
    });

    // readd more handlers
    $(".more>.title>a").click((event) => {
      event.preventDefault();
      $(event.currentTarget).parent().parent().addClass("open");
    });

    this.controller.clearBusy();

    if (this.first && vars.data.length && lazyTest()) {
      this.controller.handleUpdate({type:this.controller.REQUEST_DATA, options:{
        year: $("#yearSelector").val()
      }});
    } else {
      this.first = false;
    }
  }
}
