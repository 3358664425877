import {hasStorage} from "./util";
import templates from "./templates";

// *** Create Backbutton ***
if (hasStorage) {
  if($("#personalDetails").length || $("#showBack").length){
    const val = sessionStorage.getItem("backTo");
    if (val) {
      sessionStorage.removeItem("backTo");
      $("#mainColumn").prepend(templates.backButton({text: val}));
    }
  }
}

const setBackTitle = function(title) {
  if (hasStorage)
    sessionStorage.setItem("backTo", title);
}

export {setBackTitle}
