String.prototype.convertUmlauts = function () {
  "use strict";
  return this.toLowerCase().replace(/ä/,"a").replace(/ü/,"u").replace(/ö/,"o").replace(/ß/,"ss");
};

// enable ie8 to parse yyyy-mm-dd dates
window.parseDate = function(string) {
  return new Date(string.replace(/-/g, "/"));
};

// extend Date
Date.prototype.yyyymmdd = function() {
 var yyyy = this.getFullYear().toString();
 var mm = (this.getMonth()+1).toString(); // getMonth() is zero-based
 var dd  = this.getDate().toString();
 return yyyy + (mm[1]?mm:"0"+mm[0]) + (dd[1]?dd:"0"+dd[0]); // padding
};
