/**
* Publikationen View
* User Input Methods:
*  -different selects, checkbox
*  -text input (search)
*
* with lazyloading
* + fill page on first load
*/
import _ from "lodash";
import {ViewBase, lazyTest, lazyLoad} from "./base";
import {setBackTitle} from "../ieg/backButton";
import templates from "../ieg/templates";
import hashParams from "../ieg/hashParams";

export default class PublicationView extends ViewBase {
  constructor(element) {
    super({
      context: "publikationen",
      request: 5
    });

    this.list = $(".list-group", element);
    this.first = true;

    this.attachEvents();

    //make inputs visible if not hidden
    if (hashParams.get("hidden") !== "true") {
      $(".bootstrap-select, .select-group", element).css("display", "block");
      $(".input-group", element).css("display", "table");
    }

    // click details link -> save for back button
    this.list.on("click", ".title", () => {
      setBackTitle($("#contentTitle").text());
    })

    // lazyload
    lazyLoad(() => {
      this.controller.handleUpdate({type: this.controller.REQUEST_DATA, options: this.getParams()});
    });

    // ignore init data if there are hashparams
    if (hashParams.getList().length) {
      console.log("initial load");
      this.load();
    } else {
      this.controller.data = init.data;
      this.controller.handleUpdate({type: this.controller.UPDATE});
    }
  }

  attachEvents() {
    // handle checkbox/selects/search button
    $("#seriesSelect").change(this.load.bind(this));
    $("#authorSelect").change(this.load.bind(this));
    $("#checkOnline").change(this.load.bind(this));
    $("#yearSelect").change(this.load.bind(this));
    $("#pubSearchBtn").click(this.load.bind(this));
  }

  load() {
    this.controller.handleUpdate({type: this.controller.CHANGE_PARAMS, options: this.getParams()});
  }

  render(params) {
    var vars = _.extend({ // defaults
      data: [],
      clear: false,
      reset: false
    }, params);

    if (vars.reset || vars.clear) {
      this.list.html("");
      this.first = true;
    }

    _.each(vars.data, (element) => {
      var item = templates.publikationen(element);
      this.list.append(item);
    });

    this.controller.clearBusy();
    if (this.first && vars.data.length && lazyTest()) {
      this.controller.handleUpdate({type: this.controller.REQUEST_DATA, options: this.getParams()});
    } else {
      this.first = false;
    }
  }

  //----------------- user Inputs -------------------
  // create query options
  getParams() {
    return _.extend({}, {
      year: $("#yearSelect").val(),
      series: $("#seriesSelect").val(),
      author: $("#authorSelect").val(),
      isOnline: $("#checkOnline")[0].checked,
      searchString: $("#pubSearch").val()
    }, init.params);
  }
}
