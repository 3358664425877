/**
* Termine View
* User Input Methods:
*  -click on Calendar (selectDay, selectMonth)
*  -click on TimeTag (return to default)
*  -filter by Tag (multiple possible)
*
* NO lazyloading!
* all events for a certain month are loaded
* days / tags are filtered locally
*/
import _ from "lodash";
import {ViewBase} from "./base";
import templates from "../ieg/templates";
import calendar from "../ieg/calendar";

export default class AppointmentView extends ViewBase {
  constructor(element) {
    super({
      context: "termine",
      request: -1
    });

    this.list = $(".list-group", element);
    this.tagContainer = $(".tags", element);
    this.timeTag = $("#timeTag", element);
    this.calendar = calendar($("#calendar"), window.parseDate(init.serverTime));
    this.lastData = init.data;

    // date for filtering Items
    this.currentDate = window.parseDate(init.serverTime);
    this.state = "30days";
    this.DAY = 1000*60*60*24;

    this.attachEvents();

    // initial update, renders init.data
    this.controller.handleUpdate({type: this.controller.RESET});
  }

  attachEvents() {
    // Click on timeTag
    this.timeTag.on("click", ".tag", (event) => {
      this.switchstate("30days");
      this.currentDate = window.parseDate(init.serverTime);
      this.calendar.data.resetTo(window.parseDate(init.serverTime));
      this.controller.handleUpdate({type: this.controller.RESET});
    });

    // Click on Tag in Sidebar
    this.tagContainer.on("click", ".tag", (event) => {
      $(event.currentTarget).toggleClass("selected");
      this.renderItems(this.lastData);
    });

    // --- Calendar Actions ---
    this.calendar.on("selectDay", (event, date) => {
      console.log("selectDay:", date.getDate());
      this.currentDate = date;
      this.switchstate("day");

      this.controller.handleUpdate({type: this.controller.UPDATE});
    });

    this.calendar.on("selectMonth", (event, date) => {
      console.log("selectMonth:", date.getMonth());
      if (this.currentDate.getFullYear() === date.getFullYear() &&
          this.currentDate.getMonth() === date.getMonth()) {
        this.switchstate("month");
        this.controller.handleUpdate({type: this.controller.UPDATE});

      } else {
        this.currentDate = date;
        this.switchstate("month");
        this.controller.handleUpdate({type: this.controller.CHANGE_PARAMS, options: {
          date: this.currentDate.yyyymmdd()
        }});
      }
    });
  }

  // check whether days of dates are equal
  compareDates(date1, date2) {
    return (date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate());
  }

  // get elements in the future
  futureFilter(element) {
    const end = window.parseDate(element.endDate);
    return (end.getMonth() > this.currentDate.getMonth() ||
            end.getDate() >= this.currentDate.getDate());
  }

  // get elements in the current month
  monthFilter(element) {
    const start = window.parseDate(element.startDate);
    const end = window.parseDate(element.endDate);
    return (start.getMonth() === this.currentDate.getMonth() ||
              end.getMonth() === this.currentDate.getMonth());
  }

  // filter for current day
  dayFilter(element) {
    if (this.state !== "day")
      return true;

    const start = window.parseDate(element.startDate);
    const end = window.parseDate(element.endDate);

    const diff = Math.floor((end.getTime() - start.getTime()) / this.DAY);
    const currentDiff = Math.floor((this.currentDate.getTime() - start.getTime()) / this.DAY);

    if (currentDiff >= 0 && currentDiff <= diff) {
      return true;
    } else {
      return false;
    }
  }

  // Render Functions
  renderTags(items) {
    this.tagContainer.html("");
    const tags = [];

    _(items).filter(this.dayFilter.bind(this)).each((element) => {
      _.each(element.tags, (tag) => {
        if (tags.indexOf(tag) === -1)
          tags.push(tag);
       });
    });

    this.tagContainer.append(templates.tag({tags: tags, wrap: true}));
  }

  renderTimeTag(tagDate) {
    this.timeTag.html("");
    if (tagDate)
      this.timeTag.append(templates.tag({tags:[tagDate], wrap:false}));
  }

  // render out list items
  renderItems(items) {
    this.list.html("");
    const selectedTags = [];
    _.each($(".selected", this.tagContainer), (selectedTag) => {
      selectedTags.push($(selectedTag).text());
    });

    const tagFilter = (element) => { // filter by selected tags
      return (element.tags.length === 0 || _.intersection(element.tags, selectedTags).length > 0);
    };


    _(items).filter(tagFilter).filter(this.dayFilter.bind(this)).each((element) => {
      var item = templates.termine(element);
      this.list.append(item);
    });
  }

  switchstate(newState) {
    this.state = newState;
    switch(this.state) {
    case "30days":
      this.renderTimeTag();
      break;
    case "month":
      this.renderTimeTag(locale.monthNames[this.currentDate.getMonth()] + " " + this.currentDate.getFullYear());
      break;
    case "day":
      this.renderTimeTag(locale.formatDate(this.currentDate));
      break;
    }
  }

  // mark eventdays on the calendar
  setEventDays(data) {
    const daysInMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth()+1, 0);
    const eventDays = [];

    _.each(data, (element) => {
      const start = window.parseDate(element.startDate);
      const end = window.parseDate(element.endDate);
      if (start.getFullYear() === this.currentDate.getFullYear() &&
          start.getMonth() === this.currentDate.getMonth()) {

        const diff = Math.floor((end.getTime() - start.getTime()) / this.DAY);
        for (let i = start.getDate() - 1; i < (start.getDate() + diff) && i < daysInMonth; i++){
          eventDays[i] = true;
        }
      }
    });
    this.calendar.data.setEventDays(eventDays);
  }

  render(options) {
    const vars = _.extend({ // defaults
      data: [],
      reset: false
    }, options);

    let displayData;
    let monthData;

    if (this.state === "30days") {
      displayData = _.filter(init.data, this.futureFilter.bind(this));
      monthData = _.filter(init.data, this.monthFilter.bind(this));
      this.controller.data = monthData;
    } else {
      displayData = monthData = vars.data;
    }

    this.renderTags(displayData);
    this.renderItems(displayData);
    this.setEventDays(monthData);
    this.lastData = displayData;

    // readd more handlers
    $(".more>.title>a").click((event) => {
      event.preventDefault();
      $(event.currentTarget).parent().parent().addClass("open");
    });

    this.controller.clearBusy();
  };
}
