/**
* Projekte View
* User Input Methods:
*  -researchArea select / year select
*
* with lazyloading
* + fill page on first load
*/
import _ from "lodash";
import {ViewBase, lazyTest, lazyLoad} from "./base";
import {setBackTitle} from "../ieg/backButton";
import templates from "../ieg/templates";
import hashParams from "../ieg/hashParams";

export default class ProjectView extends ViewBase {
  constructor(element) {
    super({
      context: "projekte",
      request: 4
    });

    this.element = element;
    this.list = $(".list-group", element);
    this.first = "true";

    // listen for dropdown changes
    $("#areaSelector").change(this.load.bind(this));
    $("#stateSelector").change(this.load.bind(this));
    $("#checkOnline").change(this.load.bind(this));

    // click details link -> save for back button
    this.list.on("click", ".title", () => {
      setBackTitle($("#contentTitle").text());
    });

    // lazyload
    lazyLoad(() => {
      this.controller.handleUpdate({type:this.controller.REQUEST_DATA, options: this.getParams()});
    });

    //make inputs visible if not hidden
    if (hashParams.get("hidden") !== "true")
      $(".bootstrap-select, .select-group", element).css("display", "block");

    // initial request
    this.load();
  }

  getParams() {
    const areaSelector = $("#areaSelector", this.element);
    const stateSelector = $("#stateSelector", this.element);
    const checkOnline = $("#checkOnline");

    const params = _.extend({}, {
      researchArea: areaSelector.length ? areaSelector.val() : hashParams.get("areaSelector"),
      projectState: stateSelector.length ? stateSelector.val() : hashParams.get("stateSelector"),
      isOnline: checkOnline.length ? checkOnline[0].checked : (hashParams.get("checkOnline") == "true" ? true : undefined)
    }, init.params);

    if (params.researchArea == "all" && init.allResearch) {
      params.researchArea = null;
      params["researchArea[]"] = init.allResearch;
    }

    return params;
  }

  load() {
     this.controller.handleUpdate({type: this.controller.CHANGE_PARAMS, options: this.getParams()});
  }

  render(params) {
    const vars = _.extend({ // defaults
      data: [],
      clear: false,
      reset: false
    }, params);

    if (vars.reset || vars.clear) {
      this.list.html("");
      this.first = true;
    }

    console.log("data", vars.data, vars.data.length);

    _.each(vars.data, (element) => {
      var item = templates.projekte(element);
      this.list.append(item);
    });

    this.controller.clearBusy();
    if (this.first && vars.data.length && lazyTest()) {
      this.controller.handleUpdate({type:this.controller.REQUEST_DATA, options: this.getParams()});
    } else {
      this.first = false;
    }
  }
}
