;
import _ from "lodash";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
let mobileSize = ($(window).width() < 768);
let sizeSave = mobileSize;

// custom event for switching between mobile and desktop
$(window).resize(_.debounce(function() {
  mobileSize = ($(window).width() < 768);
  if (mobileSize !== sizeSave) {
    sizeSave = mobileSize;
    $(window).trigger("mobileSize");
  }
}, 200));

// check for localstorage
const hasStorage = (function(){
  let mod = 'test';
  try {
      sessionStorage.setItem(mod, mod);
      sessionStorage.removeItem(mod);
      return true;
  } catch(e) {
      return false;
  }
}());

export {isMobile, mobileSize, hasStorage}
