let initialized = false;
let hashParams = {list: []};

// lazy hash parsing
const parseHash = () => {
  initialized = true;

  // *** Read Parameters from Hashtag ***
  const hash = window.location.hash.substring(1).split(/\&/);
  for (let i = 0; i < hash.length; i++) {
    const split = hash[i].split("=");
    if (split.length !== 2){ continue; }
    hashParams[split[0]] = split[1];
    hashParams.list.push(split[0]);
  }

  // *** set values of selects and inputs ***
  for (let i = 0; i < hashParams.list.length; i++) {
    const key = hashParams.list[i];
    //id selector
    if (key.charAt(0) === "+") {
      const $obj = $("#" + key.substring(1));
      const obj = $obj[0];
      if (!$obj.length)
        continue;

      // set values in Selects
      if (obj.tagName === "SELECT") {
        $obj.data("selectpicker").val(hashParams[key]);
        console.log($obj.data("selectpicker"), hashParams[key]);

      } else if(obj.tagName === "INPUT" && obj.type === "checkbox") {
        obj.checked = hashParams[key] === "true";
      }
    }
  }
}

export default {
  get: (key) => {
    if (!initialized)
      parseHash();

    return hashParams[key];
  },
  getList: () => {
    if (!initialized)
      parseHash();

    return hashParams.list;
  }
}
