;
import _ from "lodash";
import {isMobile, mobileSize} from "./util";

// navbar toggle on mobile
export const collapseMenu= function(){
  var toggle= $(".navbar-toggle");
  toggle.click(function(){
    var target= $($(this).data("target"));
    var visible= target.hasClass("in");
    target[ visible ? "removeClass" : "addClass" ]("in");
  });
};

// hover dropdown on desktop, click dropdown on mobile
export const dropdownMenu= function(){
  var nav= $(".navbar-nav");
  var dropdowns= $(".dropdown", nav);
  var closeAll= function(){ dropdowns.removeClass("open"); };
  _.each(dropdowns, function(dropdown){
    var $dropdown= $(dropdown);
    var link= $(".dropdown-toggle", dropdown);
    var menu= $(".dropdown-menu", dropdown);

    var toggleMenu= function(){
      if((isMobile || mobileSize) && !$dropdown.hasClass("open")){ closeAll(); }

      // wait until browser is finished because of safari
      setTimeout(function(){ $dropdown.toggleClass("open"); }, 0);
    };

    link.click(function(event){
      if(isMobile || mobileSize){
        event.preventDefault();
        toggleMenu();
      }
    });

    $dropdown.hover(function(event){
      if(!isMobile && !mobileSize){
        toggleMenu();
      }
    });
  });

  $(window).on("mobileSize", function() {
    console.log("WOAH MOBILESIZEEVENT", mobileSize);
    closeAll();
  });
};
