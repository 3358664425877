// libs
import _ from "lodash";
import FastClick from "fastclick";
import TweenLite from "gsap/src/uncompressed/TweenLite";
import CSSPlugin from "gsap/src/uncompressed/plugins/CSSPlugin";

// common stuff
import "./ieg/common";
import {isMobile, mobileSize} from "./ieg/util";

// components
import "./libs/imagemap";
import "./libs/select";
import "./libs/dropdown";
import {collapseMenu, dropdownMenu} from "./ieg/menus";
import sideScroller from "./ieg/sideScroller";
import frontPage from "./ieg/frontPage";
import templates from "./ieg/templates";
import moduleLoader from "./modules/loader";

// ie8 compat
if (!window.console){ console = {log: function() {}} };

// *** Create Menu Functionality ***
collapseMenu();
dropdownMenu();

// *** Test for Mobile -> configure selects ***
var navigation=$("#topnav");
if (isMobile) {
  $('select').selectpicker('mobile');

  // *** Show Extra MenuItems on Mobile ***
  $(".hidden-navigation", navigation).css("display", "block");
} else {
  $('select').selectpicker();
}

// activate fastclick
FastClick(document.body);
$('img[usemap]').rwdImageMaps();

// *** Fill Sidenavigation ***
const menu = $(".navbar-nav > .active");
if (menu.length) {
  const title = menu.children(".dropdown-toggle");
  const el = $("#sidenav .nav").append($("<p class='title'><a href='"+title.attr("href")+"'>"+title.html()+"</a></p>"));
  el.append(menu.children(".dropdown-menu").html());
}

// *** FrontPage Modules ***
frontPage();

moduleLoader.load();

// *** Create Footer ***
(function(){
  var wrapper= $("#footerBoxes"),
  nav = $(".navbar-nav >li"),
  boxes = [],
  navItem, container;
  for(var i= nav.length-1; i>=0; i--){
    navItem= $(nav[i]);
    container= $(templates.footerBox({title: $.trim($(".dropdown-toggle:first", navItem).text())}));
    wrapper.prepend(container);
    boxes.push(container.children("ul").html(navItem.children("ul").html()));
  }
}());

// *** Animate Sidescroller ***
sideScroller();

/* Wrap Images with caption class */
$.fn.copyStyle= function(src, style){
  this.css(style, src.css(style));
  src.css(style, "");
  return this;
};

const subWrap = $("<div class='subWrap'></div>");
_.each($(".caption"), function(element){
  const $el = $(element);
  $el.wrap(subWrap);
  const $parent = $el.parent().copyStyle($el, "float");
  if ($parent.css("float") === "left") {
    $parent.copyStyle($el, "margin-right");
    $el.css("margin-left", "")
    .css("margin-top", "");
  } else if($parent.css("float") === "right") {
    $parent.copyStyle($el, "margin-left");
    $el.css("margin-right", "")
    .css("margin-top", "");
  } else {
    $parent.copyStyle($el, "margin-top");
  }
  $parent.copyStyle($el, "margin-bottom");

  const p = $("<p></p>");
  p.text($el.attr("alt"));
  p.css("margin-top", "10px");
  $parent.append(p);
});

/* hide image context menus */
document.body.addEventListener('contextmenu', function(event){
  if(event.target.tagName === 'IMG')
    event.preventDefault();
})

// *** Read-More Javascript ***
$(".more>.title>a").click((event) => {
  event.preventDefault();
  $(event.currentTarget).parent().parent().addClass("open");
});
