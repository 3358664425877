import _ from "lodash";
import TweenLite from "gsap/src/uncompressed/TweenLite";
import {isMobile, mobileSize} from "./util";

const sideScroller = function(){
  var scroller = $("#sidescroller");
  if(scroller.length ===0 || scroller.children("div") === 0){
    console.log("sidescroller inactive!");
    return;
  }
  console.log("sidescroller active!");

  var animateScroller= _.debounce(function(){
    if (isMobile || mobileSize){ return; }
    var scrollPos= document.documentElement.scrollTop;

    var tmp= scroller.css("top");
    scroller.css("top", "0px");
    var top= scroller.offset().top;
    scroller.css("top", tmp);

    //offsetpos just above footer
    //keep distance from footer
    var bottom=$("footer").offset().top-scroller.height()-39-50;
    if(scrollPos < top){
      scrollPos= top;
    }else if(scrollPos > bottom){
      scrollPos= bottom;
    }

    // only animate when neccessary
    var oldTop= Number(scroller.css("top").slice(0,-2));
    var newTop=(scrollPos - top);
    if(Math.round(newTop) !== Math.round(oldTop)){
      TweenLite.to(scroller, 0.3,  {top: (newTop)});
    }
  }, 500);
  $(window).scroll(animateScroller);

  $(window).on("mobileSize", function(){
    if(isMobile || mobileSize){
      scroller.css("top", "0px");
    }
  });
};

export default sideScroller;
