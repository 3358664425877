/**
* Fellow View
* User Input Methods:
*  -select new Filters
*  -change Sorting Priority / Direction
*
* with lazyloading
* + fill page on first load
*/
import _ from "lodash";
import {ViewBase, lazyTest, lazyLoad} from "./base";
import {setBackTitle} from "../ieg/backButton";
import templates from "../ieg/templates";

export default class FellowView extends ViewBase {
  constructor(element) {
    super({
      context: "fellows",
      request: 6
    });

    this.element = element;
    this.list = $(".list-group", element);
    this.listHeader = $(".list-header", element);
    this.filters = [];
    this.activeFilters = {
      year: [],
      funding: [],
      country: []
    };

    this.tagContainer = $("#tags");
    this.currentLayout = "grid";
    this.sortBy = "name";
    this.sortAscending = true;
    this.first = false;

    // remember all the filters
    _.each($(".item", element), (element) => {
      var $element = $(element);
      this.filters.push({
        value: $element.data("value"),
        name: $element.html().split(/<span/)[0],
        category: $element.parent()[0].id
      });
    });

    // setup events
    this.attachEvents();

    // use lazyload
    lazyLoad(() => {
      this.controller.handleUpdate({type:this.controller.REQUEST_DATA, options: this.getParams()});
    });

    //initial request
    this.renderHeader();
    this.load();
  }

  attachEvents() {
    // handle search
    var debounced = _.debounce(this.load.bind(this), 500);
    $("#fellowSearch").on("input", () => {
      if ($("#fellowSearch").val().length > 2) {
        console.log("search");
        debounced();
      }
    });

    // folding lists on mobile
    $(".titleBar", this.element).click((event) => {
      var $target = $(event.currentTarget);
      var icon = $(".icon", $target);
      icon.toggleClass("icon-chevron-up");
      icon.toggleClass("icon-chevron-down");
      $("#" + $target.data("open")).toggleClass("open");
    });

    // click on filter entry
    $(".items", this.element).on("click", ".item", (event) => {
      var item = $(event.currentTarget);
      item.toggleClass("active");
      var value= item.data("value");
      if (value === undefined){ return; }

      var filter = _.find(this.filters, (el) => el.value === value);
      if (item.hasClass("active")) {
        this.addFilter(filter);
      } else {
        this.removeFilter(filter);
      }
    });

    // click on remove all tag
    $("#allTag").click(() => {
      $(".item", this.element).removeClass("active");
      this.activeFilters = {
        year: [],
        funding: [],
        country: []
      };

      this.renderTags();
      this.load();
    });

    // click on tag
    this.tagContainer.on("click", ".tag", (event) => {
      const value = $(event.currentTarget).data("value");
      const filter = _.find(this.filters, (el) => el.value === value);
      console.log(filter.category);
      _.each(document.querySelectorAll("#" + filter.category+" .item"), (element) => {
        const $element = $(element);
        if ($element.data("value") === filter.value)
          $element.removeClass("active");
      });

      this.removeFilter(filter);
    });

    // click on viewButtons
    $("#viewButtons .btn").click((event) => {
      $(event.currentTarget).addClass("active").siblings().removeClass("active");
      const isGrid = (event.currentTarget.id === "gridBtn");
      this.currentLayout = isGrid ? "grid" : "list";
      this.list[ isGrid ? "addClass" : "removeClass" ]("gridLayout");
      this.listHeader[ isGrid ? "addClass" : "removeClass" ]("gridLayout");
      if (isGrid)
        this.sortAscending = true;

      this.renderHeader();
      this.controller.handleUpdate({type:this.controller.UPDATE});
    });

    // click on header item (sorting)
    this.listHeader.on("click", ".sort", (event) => {
      const $this = $(event.currentTarget);
      const category = $this.data("sortby");

      // handle sort Order
      if (category !== this.sortBy) {
        this.sortAscending = true;
        this.sortBy = category;
      } else if (this.currentLayout === "list") {
        this.sortAscending = !this.sortAscending;
      }

      // display correct icons in listLayout
      if (this.currentLayout === "list") {
        $this.siblings().children(".fa")
        .removeClass("fa-sort-down")
        .removeClass("fa-sort-up")
        .addClass("fa-sort");

        $this.children(".fa").removeClass("fa-sort")
        [this.sortAscending? "addClass": "removeClass"]("fa-sort-up")
        [this.sortAscending? "removeClass": "addClass"]("fa-sort-down");
      }

      this.load();
    });

    // click details link -> save for back button
    this.list.on("click", ".name", function(){
      setBackTitle($("#contentTitle").text());
    });
  }

  // create query options
  getParams() {
    return _.extend({}, {
      sortBy: this.sortBy,
      sortAscending: this.sortAscending,
      yearFilters: this.filters.year,
      fundingFilters: this.filters.funding,
      countryFilters: this.filters.country,
      searchString: $("#fellowSearch").val(),
      year: this.activeFilters.year,
      funding: this.activeFilters.funding,
      country: this.activeFilters.country
    }, init.params);
  }

  load() {
    this.controller.handleUpdate({type: this.controller.CHANGE_PARAMS, options: this.getParams()});
  }

  addFilter(filter) {
    const f = this.activeFilters[filter.category];
    if (f.indexOf(filter.value) === -1) {
      f.push(filter.value);
      // rerender
      this.renderTags();
    }
    this.load();
  }

  removeFilter(filter) {
    var f = this.activeFilters[filter.category];
    var index = _.indexOf(f, filter.value);
    if (index > -1) {
      f.splice(index, 1);
      // rerender
      this.renderTags();
    }
    this.load();
  }

  renderHeader() {
    this.listHeader.html("");

    const getIcon = (category) => {
      if (this.sortBy === category) {
        return this.sortAscending ? "fa-sort-up" : "fa-sort-down";
      } else {
        return "fa-sort";
      }
    };

    this.listHeader.append(templates.fellowListHeader({
      grid: (this.currentLayout === "grid"),
      yearIcon: getIcon("year"),
      nameIcon: getIcon("name"),
      countryIcon: getIcon("country"),
      sortAscending: this.sortAscending
    }));
  }

  renderTags() {
    var l = this.activeFilters.year.length ||
            this.activeFilters.funding.length ||
            this.activeFilters.country.length;
    $("#allTag").css("display", l ? "inline-block" : "none");

    var displayFilters = [];
    var getFilterName = (value) => {
      displayFilters.push(_.find(this.filters, (el) => el.value === value));
    };

    _.each(this.activeFilters.year, getFilterName);
    _.each(this.activeFilters.funding, getFilterName);
    _.each(this.activeFilters.country, getFilterName);

    this.tagContainer.html("");
    this.tagContainer.append(templates.fellowTags({filters: displayFilters}));
  };

  //----------------- render Function -------------------
  render(variableObject) {
    const vars = _.extend({ // defaults
      data: [],
      clear: false,
      reset: false
    }, variableObject);

    if (vars.reset || vars.clear) {
      this.list.html("");
      this.first = true;
    }

    if (vars.count) {
      $("#fellowCount").text(vars.count+" Fellows");
    }

    _.each(vars.data, (element) => {
      const html = templates[this.currentLayout === "grid" ? "fellowgrid" : "fellowlist"](element);
      this.list.append(html);
    });

    this.controller.clearBusy();
    if (this.first && vars.data.length && lazyTest()) {
      this.controller.handleUpdate({type: this.controller.REQUEST_DATA, options: this.getParams()});
    } else {
      this.first = false;
    }
  }
}
